import React, { useEffect } from "react";
import { Redirect } from 'react-router-dom';
import Cookies from "js-cookie";

export default function CierraSesion() {

   useEffect(() => {
      if(localStorage.getItem("cookieDomain") !== undefined && localStorage.getItem("cookieDomain") !== null) {
          Cookies.remove("us-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
          Cookies.remove("cn-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
          Cookies.remove("vr-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
          Cookies.remove("sy-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
          Cookies.remove("mg-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
      }
      localStorage.clear();
   }, []);

   return(
      <Redirect to = "/sesiones/login"/>
   )
   
}