import React, { useEffect } from "react";
import { Redirect } from 'react-router-dom';

import Header from "../Componets/Header/Header";
import Footer from "../Componets/Footer/Footer";
import { ReactComponent as Heder_Logo } from "../../assets/img/Header/ID_sesiones_blanco.svg";
import { ReactComponent as Heder_Logo_User } from "../../assets/img/Header/user.svg";
import CardsController from "../Componets/Tarjetas/CardsController";
import Cookies from "js-cookie";
import {refrescaSesion} from "../../Router/Auth";
import { validateToken } from "../../Config/Helper";
import axios from "axios";

import "../../App.css"; 
import { Layout } from "antd";
const { Content } = Layout;

export default function Aplications() {

  useEffect(() => {
    console.log("System: ", Cookies.get("sy-ses"));
    if(localStorage.getItem("username") !== null &&  localStorage.getItem("username") !== undefined && localStorage.getItem("okRefresacar") !== null)
      refrescaSesion();

    if(localStorage.getItem("okRefresacar") === null)
      localStorage.setItem("okRefresacar", 1);

    if(Cookies.get("sy-ses") !== undefined){
      axios
      .get(window.location.origin + "/JsonHelpers/jsonUrl.json")
      .then((response) => {
        console.log("System verificando: ", Cookies.get("sy-ses"));
        if (response.status === 200) {
          console.log(localStorage.getItem("accessToken"));
          if(validateToken(localStorage.getItem("accessToken"))) {
            const consejo =  219;
            if (Cookies.get("sy-ses").toString() === consejo.toString()) {
              window.location.href = response.data.urlConsejo;
            } else {
              window.location.href = response.data.urlJuntas;
            }
          }

        }
      });
    }
  }, []);

  return (
    <div>
      {(localStorage.getItem("username") === null ||  localStorage.getItem("username") === undefined) ?
        <Redirect to = "/sesiones/login"/>
      : Cookies.get("sy-ses") === undefined &&
          <Layout style={{ minHeight: "100vh" }}>
            <Header
              Logo={Heder_Logo}
              LogoUser={Heder_Logo_User}
              User={JSON.parse(localStorage.getItem("username")).user}
            />
            <Content style={{ paddingTop: "5%", background: "#fff" }}>
              <CardsController />
            </Content>
            <Footer />
          </Layout>
      }
    </div>
  );
}
