import React from "react";
import Routes from "../src/Router/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./index.css";
function App() {
  return <Routes />;
}

export default App;
