import React, { Component } from "react";
import CardItem from "./CardItem";
import "antd/dist/antd.css";
import consejo from "../Tarjetas/Consejo_carta.svg";
import junta from "../Tarjetas/Junta_carta.svg";
import consejoMobile from "../Tarjetas/Consejo_01.svg";
import juntaMobile from "../Tarjetas/Juntas_01.svg";
import Container from "@material-ui/core/Container";
import Cookies from "js-cookie";
import { Col, Row } from "antd";

// function iteraEnSistemas(sistemasHabilitados, className) {
//   var imageConsejo =
//     className === "col d-none d-md-block" ? consejo : consejoMobile;
//   var imageJunta = className === "col d-none d-md-block" ? junta : juntaMobile;

//   // let tempUser = JSON.parse(localStorage.getItem("username"));

 
//   return sistemasHabilitados.map((app) => (
    
//     <div key={app.idSistema} className={className}>
//       {/* <Col xs={24} sm={24} md={12} lg={12}> */}
//         <CardItem
//           style={{ borderRadius: "10px" }}
//           idSistema={app.idSistema}
//           cardData={app}
//           nombreCortoSistema={app.nombreCortoSistema}
//           nombreSistema={app.nombreSistema}
//           urlImg={app.idSistema === 219 ? imageConsejo : imageJunta}
//           rutaDeApp={
//             app.idSistema === 219 ? "/sesiones/consejo" : "/sesiones/junta"
//           }
//         />
//       {/* </Col> */}
//     </div>
//   ));
// }

export class CardsController extends Component {
  componentDidMount() {
    // console.log(Cookies.get("mg-ses"));
    //hacer llamada a archivo jsonURLS
    const cookieDomain = localStorage.getItem("cookieDomain")
    if (Cookies.get("mg-ses") !== undefined) {
      alert("Ocurrio un error.");
      Cookies.remove("mg-ses", { path: "/", domain: cookieDomain });
    }
  }

  //Lista de aplicaciones disponibles
  apps = JSON.parse(localStorage.getItem("permisoSistemas")).filter((item)=> {
          return (item.idSistema === 219) | (item.idSistema === 227) ;
        });

  imagenSistema = (idSistema, tipo) =>{
    if(tipo === 1){
      if(idSistema === 219){
        return consejo;
      }else{
        return junta;
      }
    }else{
      if(idSistema === 219){
        return consejoMobile;
      }else{
        return juntaMobile;
      }
    }
  }

  render() {
  //     const sistemasHabilitados = this.apps.filter(function (item) {
  //     return (item.idSistema === 219) | (item.idSistema === 227);
  //       });
  //     if (sistemasHabilitados.length === 1) {
  //       return (
  //         <Container className="container">
  //           <div className="container">
  //             <div className="row " style={{ width: "850px" }}>
  //               {iteraEnSistemas(sistemasHabilitados, "col d-none d-md-block")}
  //             </div>
  //             <div className="row " style={{ width: "850px" }}>
  //               {iteraEnSistemas(sistemasHabilitados, "col-md-4 d-md-none")}
  //             </div>
  //           </div>
  //         </Container>
  //       );
  //     } else {
  //       return (
  //         <div className="container containerApps shadow-none p-3 mb-5 bg-white rounded" >
  //           <div className="row ">
  //             {iteraEnSistemas(sistemasHabilitados, "col d-none d-md-block")}
  //             {iteraEnSistemas(sistemasHabilitados, "col-md-4 d-md-none")}
  //           </div>
  //         </div>
  //       );
  //     }
  // }
  return(
    <Row >
      <Col span={this.apps.length === 1 ? 8 : 18} offset={this.apps.length === 1 ? 8 : 3}>
        <Row>
          {this.apps.map(item =>
              <div>
                <Col xs={0} sm={0} md={this.apps.length === 1 ? 24 : 11} lg={this.apps.length === 1 ? 24 : 11} style={{margin:"10px"}} >
                  <CardItem
                    style={{ borderRadius: "10px" }}
                    idSistema={item.idSistema}
                    cardData={item}
                    nombreCortoSistema={item.nombreCortoSistema}
                    nombreSistema={item.nombreSistema}
                    urlImg={this.imagenSistema(item.idSistema, 1)}
                    rutaDeApp={
                      item.idSistema === 219 ? "/sesiones/consejo" : "/sesiones/junta"
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} style={{marginBottom:"20px"}}>
                  <CardItem
                    style={{ borderRadius: "10px" }}
                    idSistema={item.idSistema}
                    cardData={item}
                    nombreCortoSistema={item.nombreCortoSistema}
                    nombreSistema={item.nombreSistema}
                    urlImg={this.imagenSistema(item.idSistema, 2)}
                    rutaDeApp={
                      item.idSistema === 219 ? "/sesiones/consejo" : "/sesiones/junta"
                    }
                  />
                </Col>
              </div>
          )}
        </Row>
      </Col>
    </Row>
  )
}
}

export default CardsController;
