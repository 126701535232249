import React, { useState } from "react";
import { Textbox } from "react-inputs-validation";
import "./inputINE.css";
import ReactTooltip from "react-tooltip";
import {
  EyeOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';

TextField.defaultProps = {
  tooltipPosition: "right",
  placeHolder: "Ingresa tus datos",
  type: "text",
  tooltipMessage: "",
  paddingLeft: "10px",
  disabled: false,
  value: "",
};
//para cuando Pau tenga listo los estilos de Tooltips
// const styles = {
//     display: 'table-cell',
//     height: '60px',
//     width: '80px',
//     textAlign: 'center',
//     background: 'white',
//     verticalAlign: 'middle',
//     border: '5px solid white',
// };
export default function TextField(props) {
  const { reduxStore } = props; //
  const {
    placeHolder,
    id,
    name,
    regex,
    label,
    icon,
    warningMessage,
    tooltipMessage,
    tooltipPosition,
    paddingLeft,
    disabled,
    value,
  } = props;
  
  console.log("props login", props)  
  const [className, setClassName] = useState("");
  const [validationIcon, setValidationIcon] = useState("");
  const [viewPass, setViewPass] = useState(false);
  const [type, setType] = useState(props.type)
  const [contentSize, setContentSize] = useState(0)
  const [maxLength] = useState(props.maxLength > 0 ? props.maxLength : 50)
  const click = () => {
    if (!viewPass) {
      setViewPass(true)
      setType('text')
    }
    else {
      setViewPass(false)
      setType('password')
    }
  }
  const handleChange = (content) => {
    setContentSize(content.length)
    let isValid = regex.test(content);
    reduxStore.dispatch({
      type: "",
      id: id,
      content: content,
      warningMessage: warningMessage,
      isValid,
    });
    setClassName(reduxStore.getState().className);
    setValidationIcon(reduxStore.getState().icon);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: "0px",
          top: "50%",
          visibility: "showed",
          zIndex: "1",
          transform: "translateY(-69.5%)",
          color: "#666",
          paddingLeft: "10px",
        }}
      >
        {icon}
      </div>

      <div data-tip={tooltipMessage}>
        <Textbox
          style={{ paddingLeft: "30px" }}
          value={value}
          attributesInput={{
            id: id,
            name: name,
            placeholder: placeHolder,
            type: type,
            maxLength: maxLength,
            className:
              "ant-input " +
              className +
              " ant-input-affix-wrapper ant-input-affix-wrapper-lg",
          }}
          onChange={(textInput) => {
            handleChange(textInput);
          }}
        />
      </div>
      <ReactTooltip place={tooltipPosition} effect="solid" />
      {props.name === 'password' && contentSize >= 1 ?
        <div
          className="disable"
          style={{
            position: "absolute",
            right: "30px",
            top: "50%",
            visibility: "showed",
            zIndex: "1",
            transform: "translateY(-69.5%)",
            color: "#666",
            paddingLeft: "10px",
          }}
          onClick={click}
        >
          {viewPass == true ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </div>
        : ""
      }
      <div
        className="disable"
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          visibility: "showed",
          zIndex: "1",
          transform: "translateY(-69.5%)",
          color: "#666",
          paddingLeft: "10px",
        }}
      >
        {validationIcon}
      </div>
    </div>
  );
}
