import React, { useState } from "react";
import { Layout, Button } from "antd"; //Se importan los componentes de ant desig
import LogoUser from "../../../assets/img/Header/user.svg";
import Logo_Header from "../../../assets/img/Header/ID_sesiones_blanco.svg";
import "./Header.scss";
import "antd/dist/antd.css";
import { logout } from "../../../Router/Auth";
import { Dialog, DialogContent, DialogActions, DialogTitle } from "@material-ui/core";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function Header(props) {
  const { User } = props; //Se recibe el logo por props
  const { Header } = Layout; //Se importa el header del componente del Layout
  const [alertOpen, setAletOpen] = useState(false);

  const handleClickOpen = () => {
    setAletOpen(true);
  };

  const handleClose = () => {
    setAletOpen(false);
  };

  return (
    <Header>
      <div className="menu-top">
        {/*Se crea la estructura del header  */}
        <div className="menu-top__logo">
          {/* <Logo_Header /> */}
          <img src={Logo_Header} className="logo" />
        </div>
        <div className="menu-top__ayuda">
          <p>
            {User && (
              <>
                <img src={LogoUser} className="user" alt="user" />
                {User}&nbsp;&nbsp;&nbsp;|
            <Button
                  className="ant-btn-link hideOnMobile"
                  onClick={handleClickOpen}
                >
                  <CloseCircleOutlined
                    style={{
                      position: "relative",
                      top: "8.5px",
                      transform: "translateY(-69.5%)",
                      fontSize: "15px",
                    }}
                  />
              Cerrar sesión
            </Button>
                <Dialog open={alertOpen}>
                  <DialogTitle id="form-dialog-title">¿Deseas Cerrar Sesión?</DialogTitle>
                  <DialogContent>Una vez cerrada la sesión deberás volver a ingresar usuario y contraseña</DialogContent>
                  <DialogActions>
                    <Button type="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button type="primary" onClick={() => logout()}>Aceptar</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </p>
        </div>
      </div>
    </Header>
  );
}
