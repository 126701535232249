import React  from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import HomeLogin from "../Login/Pages/Home";
import Aplications from "../Login/Pages/Aplications";
import CierraSesion from "../Login/Pages/CierraSesion";

 
import Page404 from "../Page404/Page404";

export default () => (

    <Router>
        <Switch>
            <Redirect from="/" to="/sesiones/login" exact />
            <Route path='/sesiones/login' component={HomeLogin} />

            <Route path='/sesiones/aplicaciones' component={Aplications} />
            <Route path='/sesiones/cierraSesion' component={CierraSesion} />
            <Route component={Page404} />
        </Switch>
    </Router>
)



