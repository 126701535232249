import React, { useState } from "react";
import { Form } from "antd";
import Container from "@material-ui/core/Container";
import Recaptcha from "react-recaptcha";
import "./Login.scss";
import axios from "axios";
import { Button } from "antd";
import alertINE from "../Alert/AlertsINE";
import InputINE from "../Input/inputINE";
import { createStore } from "redux";
import { inputReducer } from "../Input/reducer";
import {
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { descifraToken } from "../../../Config/Helper";

let storeFormulario = createStore(inputReducer);

export default function Formulario(props) {
  const CAP_KEY = "6Len8ekUAAAAALaah5js5KtSdPboaGH703DuJgV2";
  const { Logo } = props; //Se recibe el logo por props
  const [capcha, setCapcha] = useState(false);

  const [usuario, setUser] = useState("nil");
  const [password, setPassword] = useState("nil");
  const [messageMobile, setMessageMobile] = useState("nada");

  const [inputsValidos, setInputsValidos] = useState([false, false]); //arreglo que debe llenarse con true cuando todos los input sean válidos

  const verifyCallback = (response) => {
    console.log("captcha marked");
    setCapcha({ capcha }); //función que comprueba que el recapcha se selecciono correctamente
    console.log(capcha);
  };
  const onloadCallback = () => {
    console.log("captcha successfully loaded"); //Realiza un callback al capcha
  };

  storeFormulario.subscribe(() => {
    let state = storeFormulario.getState();
    inputsValidos[state.id] = state.isValid;
    if (!state.isValid) {
      setMessageMobile(state.warningMessage);
    } else {
      setMessageMobile("nada");
      switch (state.id) {
        case 0: //input usuario
          setUser(state.content);
          break;
        case 1: //input password
          setPassword(state.content);
          break;
        default:
          break;
      }
    }
  });

  const login = (e) => {
    e.preventDefault();
    //if (!capcha) {

    let checker = (val) => val.every((v) => v === true);

//    if (false) {
//      //Se verifica el capcha
//      //alert("Selecciona el capcha");
//      alertINE("info", "Selecciona el captcha");
//    } else if (checker(inputsValidos)) {
      //Se realiza la petición al server

   
       if (!capcha) {
           //Se verifica el capcha
           //alert("Selecciona el capcha");
           alertINE("info", "Selecciona el captcha");
      } else if (checker(inputsValidos)) {

    	
    	
      //Se realiza la peticion
      axios
        .get(window.location.origin + "/JsonHelpers/jsonUrl.json")
        .then((responseJSON) => {

          if (responseJSON.status === 200) {
            localStorage.setItem("aplicaciones", responseJSON.data.urlLogin);
            const url = responseJSON.data.serverCentral + '/ws/validateUser';
            console.log(url);
            axios
              .post(
                url,
                //inputs2
                {
                  //          "usuario": "alfonso.rios", // oc
                  // "usuario": "armando.calleja", // oc
                  //"usuario": "desa05-05", // JD
                  //"usuario": "desa05-01", // JD
                  //"usuario": "desa05-00", // JL
                  //          "password": "password",
                  usuario: usuario,
                  password: password,
                }
              )
              .then((response) => {
                const { code, message } = response.data.entity;
                setMessageMobile(response.data.entity.message);
                console.log(response);
                if (code === 400 || code === 500) {
                  console.log("eroro " + message);
                  alertINE("error", message);
                } else {
                  if (code === 200) {
                    console.log("accessToken", descifraToken(response.data.entity.datosUsuario.tknA));
                    localStorage.setItem("accessToken", response.data.entity.datosUsuario.tknA);
                    console.log("datosUsuario", response.data.entity.datosUsuario);

                    localStorage.setItem(
                      "permisoSistemas",
                      JSON.stringify(
                        response.data.entity.datosUsuario.permisoSistemas
                      )
                    );
                    localStorage.setItem(
                      "username",
                      JSON.stringify({
                        user: response.data.entity.datosUsuario.username,
                        pass: response.data.entity.datosUsuario.claveCifrada,
                      })
                    );
                    //localStorage.setItem("ps", password);
                    //console.log(response.data.entity.datosUsuario.username)

                    window.location.href = "/sesiones/aplicaciones"; 
                  } else {
                    console.log("hay error");
                    alertINE("error", "Ocurrió un error, intenta más tarde");
                  }
                }
              })
              .catch((error) => {
                console.log("ocurrio un error ", error);
                //message.error('Error al intentrar acceder a el servidor', 7);
                //alert("Error al intentrar acceder a el servidor ")
                alertINE("error", "Error de conexión. Revisa tu conexión de red.");
              });

          }
        }
        )
    } else {
      setMessageMobile("Debes llenar todos los campos");
    }
  };

  return (
    <Container fixed>
      <Form.Item className="d-none d-md-block"></Form.Item>
      <div className="divFormulario">
        <Logo className="logoFormulario" />
      </div>
      <div
        className={"alertMessageAyuda"}
        style={{ visibility: messageMobile !== "nada" ? "visible" : "hidden" }}
      >
        <InfoCircleOutlined
          style={{
            position: "relative",
            top: "8.5px",
            transform: "translateY(-69.5%)",
            color: "#79134c",
            fontSize: "16px",
          }}
        />
        {" " + messageMobile}
      </div>
      <Container fixed>
        <Form className="login-form" onSubmit={login}>
          <Form.Item className="d-none d-md-block" />
          <div className={"labelsINE"}>Usuario</div>
          <InputINE
            id={0}
            reduxStore={storeFormulario}
            onSelectLanguage={() => storeFormulario.dispatch()}
            label=""
            name="usuario"
            placeHolder="Ingresa tu usuario."
            type="text"
//            regex={/^[\w\d]+(?:(\.|\-)[\w\d]+)+$/}
            regex={/^([\w\d.\-_@])+$/}	
            icon={<UserOutlined style={{ color: "#d5007f" }} />}
            warningMessage="Nombre de usuario inválido"
            tooltipMessage="Nombre de usuario"
          />

          <Form.Item />
          <div className={"labelsINE"}>Contraseña</div>
          <InputINE
            id={1}
            reduxStore={storeFormulario}
            onSelectLanguage={() => storeFormulario.dispatch()}
            label=""
            name="password"
            placeHolder="Ingresa tu contraseña."
            type="password"
            regex={/(.)+/}
            icon={<LockOutlined style={{ color: "#d5007f" }} />}
            warningMessage="Contraseña inválida"
            tooltipMessage="Contraseña"
            maxLength={50}
          />

          <Form.Item />

          <div
            className="ant-row ant-form-item"
            style={{ marginLeft: "-15px", width: "50%", alignSelf: "left" }}
          >
            <Form.Item
              className="captcha__capcha"
              style={{ alignContent: "right" }}
            >
              <Recaptcha
                onloadCallback={onloadCallback}
                verifyCallback={verifyCallback}
                sitekey={CAP_KEY}
                hl={"es"}
                render="explicit"
                tabIndex="3"
              />
            </Form.Item>
          </div>
          <Form.Item className="d-none d-md-block" />
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="login-form-button"
              tabIndex="4"
            >
              <div>Iniciar Sesión</div>
            </Button>
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <a
              className="aINE"
              style={{ color: "#D5007F", textDecoration: "underline" }}
              href="https://cua.ine.mx/claveacceso/"
              tabIndex="5"
            >
              Cambiar Contraseña
            </a>
          </div>
          <div style={{ textAlign: "center" }}>
            <a
              className="aINE"
              style={{ color: "#D5007F", textDecoration: "underline" }}
              tabIndex="6"
              href="https://www.ine.mx/proteccion-datos-personales/"
            >
              Protección de datos
            </a>
          </div>
          <Form.Item />
          <Form.Item />
        </Form>
      </Container>
    </Container>
  );
}
