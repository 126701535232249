import jwtDecode from "jwt-decode";
import {descifraToken} from "../Config/Helper";
import axios from "axios";
import Cookies from "js-cookie";
import {validaTimeToken} from "../Config/Helper";
import { useHistory } from 'react-router-dom'
// import { basePath } from "../Config/Server";

/**
 * Elimina el token y recarga la pagina
 */
export function logout() {
    const url = localStorage.getItem("aplicaciones");
    if(localStorage.getItem("cookieDomain") !== undefined && localStorage.getItem("cookieDomain") !== null) {
        Cookies.remove("us-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
        Cookies.remove("cn-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
        Cookies.remove("vr-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
        Cookies.remove("sy-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
        Cookies.remove("mg-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
    }
    localStorage.clear();
    window.location.href = url + "/sesiones/login";
}

/**
 * cierrra session de la pagina aplicaciones
 */
export function exitApp() {
    //console.log("elino el de token")
    if (localStorage.getItem("accessToken") != null) {
        console.log("tiene token ")
        axios
            .get(window.location.origin + "/JsonHelpers/jsonUrl.json")
            .then((responseJSON) => {
                if (responseJSON.status === 200) {
                    const url = responseJSON.data.serverCentral + '/cierraSesion';
                    console.log(url);
                    // const url = `${basePath}/cierraSesion`
                    axios.post(url, {},
                        {
                            headers: {
                                'Authorization': localStorage.getItem("accessToken")
                            }

                        }).then(resp => {
                            console.log("Exito !!  ", resp)
                            localStorage.removeItem("accessToken")
                            localStorage.removeItem("menu")
                            return true;
                        }).catch(err => {
                            console.log("Error!  ", err)

                            return false;
                        })
                }
            }
            )
    } else {
        return true;
    }
}

/**
 * verfifca que tenga aplicaciones y que sea valido 
 */
export function isLogin() {
    try {
        console.log("token", localStorage.getItem('accessToken'));
        if(localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined){
          if(validaTimeToken(localStorage.getItem('accessToken'))){
            refrescaSesion();
            return  true;
          }else{
            const apps = JSON.parse(localStorage.getItem('permisoSistemas'));
            if(apps.length != null && apps.length > 0 && apps[0].idSistema){
              refrescaSesion();
              return  true;
            }else{
              return  false;
            }
          }
        }else{
          return  false;
        }
    } catch (error) {
        return false
    }
}

export function isValidateToken() {
  try {
      if(localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined){
        if(validaTimeToken(localStorage.getItem('accessToken'))){
          refrescaSesion();
          return  true;
        }else{
            return  false;
        }
      }else{
        return  false;
      }
  } catch (error) {
      return false
  }
}

/**
 * verifica que tenga un token valido 
 * y tenga aplicaciones 
 */
export function isAuthenticated() {
    const token = localStorage.getItem('accessToken');
    try {
        let user = jwtDecode(descifraToken(token));
        return (user.sub != null && token != null) ? true : false
    } catch (error) {
        return false;
    }

}

export function refrescaSesion() {
    let username = JSON.parse(localStorage.getItem("username"));
    let user = username.user;
    let pass = username.pass;
    axios
    .get(window.location.origin + "/JsonHelpers/jsonUrl.json")
    .then((responseJSON) => {
      if (responseJSON.status === 200) {
        localStorage.setItem("aplicaciones", responseJSON.data.urlLogin);
        const url = responseJSON.data.serverCentral + '/ws/validateUser';
        console.log(url);
        axios
          .post(
            url,
            {
              usuario: user,
              password: pass,
              idBitacora: 0
            }
          )
          .then((response) => {
            const { code, message } = response.data.entity;
            if (code === 400 || code === 500) {
                let aplicaciones =  localStorage.getItem('aplicaciones');
                if(localStorage.getItem("cookieDomain") !== undefined && localStorage.getItem("cookieDomain") !== null) {
                    Cookies.remove("us-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("cn-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("vr-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("sy-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("mg-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                }
                localStorage.clear();
                console.log("error " + message);
                alert("Ocurrió un error al refrescar la sesión, favor de iniciar sesión de nuevo.");
                window.location.href = aplicaciones + "/sesiones/login";
            } else {
              if (code === 200) {
                console.log("accessToken", descifraToken(response.data.entity.datosUsuario.tknA));
                localStorage.setItem("accessToken", response.data.entity.datosUsuario.tknA);
                console.log("datosUsuario", response.data.entity.datosUsuario);

                localStorage.setItem(
                  "permisoSistemas",
                  JSON.stringify(
                    response.data.entity.datosUsuario.permisoSistemas
                  )
                );
                localStorage.setItem(
                  "username",
                  JSON.stringify({
                    user: response.data.entity.datosUsuario.username,
                    pass: response.data.entity.datosUsuario.claveCifrada,
                  })
                );
              } else {
                let aplicaciones =  localStorage.getItem('aplicaciones');
                if(localStorage.getItem("cookieDomain") !== undefined && localStorage.getItem("cookieDomain") !== null) {
                    Cookies.remove("us-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("cn-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("vr-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("sy-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("mg-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                }
                localStorage.clear();
                console.log("hay error");
                alert("Ocurrió un error al refrescar la sesión, favor de iniciar sesión de nuevo.");
                window.location.href = aplicaciones + "/sesiones/login";
              }
            }
          })
          .catch((error) => {
                let aplicaciones =  localStorage.getItem('aplicaciones');
                if(localStorage.getItem("cookieDomain") !== undefined && localStorage.getItem("cookieDomain") !== null) {
                    Cookies.remove("us-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("cn-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("vr-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("sy-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                    Cookies.remove("mg-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
                }
                localStorage.clear();
                console.log("ocurrio un error ", error);
                alert("Ocurrió un error de conexión, favor de iniciar sesión de nuevo.");
                window.location.href = aplicaciones + "/sesiones/login";
          });

      }
    }
    )
}