import React from "react";
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons'


/*
* cambia el eatado dependiendo de la accion 
* @param {*} state 
* @param {*} action texto 
*/
export function inputReducer (state = [], action){
    var isValid = action.isValid
    let className = changeClassName(isValid)
    let icon      = changeIcon(isValid)
    return state = {isValid:action.isValid, id:action.id, content:action.content, warningMessage:action.warningMessage, className:className, icon:icon};
 }

 function changeClassName(isValid)  {
     
    switch(isValid){
        case true:
            return 'correcto'
        case false:
            return 'incorrecto'
        default:
            return 'disabled'
    }
}
function changeIcon(isValid)  {
    switch(isValid){
        case true:
            return <CheckCircleFilled style={{ color: '#389b73' }} />
        case false:
            return <CloseCircleFilled style={{ color: '#79134c' }} />
        default:
            return ''
    }
}