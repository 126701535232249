import React from 'react'
import { notification } from "antd";
import {
    CloseCircleFilled, WarningFilled,
    CheckCircleFilled, InfoCircleFilled
} from '@ant-design/icons';

export default function showAlert(code, message) {
    //No se logró autenticar. Datos de ingreso--- incorrectos
    const key = 'updatable';
    var icon = getIconType(code)
    var width = message.length > 40 ? message.length * 10.23 : 400
    notification.open({
        key,
        className: 'ant-notification-notice',
        message: message,
        placement: 'topLeft',
        duration: 2,
        style: getStyle(code, width),
        icon: icon,
    });
}

function getIconType(code) {
    var color = { 'succes': '#409AA1', 'warning': '#EB8B18', 'info': '#17A7E5', 'error': '79134C' }

    const style = { verticalAlign: 'middle', color: color.code }

    console.log(style)
    var icon = <CloseCircleFilled style={style} />//por defecto tiene el valor de error

    switch (code) {
        case 'success':
            icon = <CheckCircleFilled style={style} />
            break
        case 'warning':
            icon = <WarningFilled style={style} />
            break
        case 'info':
            icon = <InfoCircleFilled style={style} />
            break
        default:
            break
    }
    return icon
}
function getStyle(code, width) {
    var borderColor = '#79134c'
    var background = '#ffeae6'

    switch (code) {
        case 'success':
            borderColor = '#389b73'
            background = '#caf9e2'
            break
        case 'warning':
            borderColor = '#ea8b18'
            background = '#ffe9cc'
            break
        case 'info':
            borderColor = '#17a7e5'
            background = '#def8ff'
            break
        default:
            break

    }
    const alertStyle = {
        verticalAlign: 'middle', background: background,
        width: width + 'px', height: '56px', borderColor: borderColor,
        borderWidth: '1px', borderStyle: 'solid',
        font: 'Roboto', fontSize: '14px'
    }

    return alertStyle
}

