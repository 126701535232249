import React from "react";
import "antd/dist/antd.css";
import axios from "axios";
import Cookies from "js-cookie";
import { validateToken } from "../../../Config/Helper";
import { Card } from "antd";

export default function CardItem(props) {
  const { idSistema, urlImg } = props;
  const Login = (e) => { 
    axios
      .get(window.location.origin + "/JsonHelpers/jsonUrl.json")
      .then((response) => {
        console.log("urls::::::::::Ponch");
        console.log(response);
        if (response.status === 200) {

          if(validateToken(localStorage.getItem("accessToken"))) {
            console.log("cargado desde jsonUrl::::::____")
            console.log(response.data);
            e.preventDefault();
            let tempUser = JSON.parse(localStorage.getItem("username"));
            
            // const data = {
            //     "usuario": tempUser.user, //"armando.calleja",
            //     "password": tempUser.pass, //"luapfeVxf+UvOjMJ9xRZjA=="
            //     "versionAplicacion": "1",
            //     "idSistema": idSistema
            // }
            
            localStorage.setItem("idSistema", idSistema);
            localStorage.setItem("cookieDomain", response.data.cookieDomain);
            
            Cookies.set("us-ses", tempUser.user, { path: "/", domain: response.data.cookieDomain });
            Cookies.set("cn-ses", tempUser.pass, { path: "/", domain: response.data.cookieDomain });
            Cookies.set("vr-ses", "1", { path: "/", domain: response.data.cookieDomain });

            if (idSistema === 219) {
              Cookies.set("sy-ses", 219, { path: "/", domain: response.data.cookieDomain });
              window.location.href = response.data.urlConsejo;
            } else {
              Cookies.set("sy-ses", 227, { path: "/", domain: response.data.cookieDomain });
              window.location.href = response.data.urlJuntas;
            }
          }

        }
      });


    // axios.post(url, data).then(response => {
    //     if (response.status === 200) {
    //         console.log(response.data.entity)
    //         if (response.data.entity.code === 200) {
    //             //localStorage.setItem("data", JSON.stringify(response.data.entity.datosUsuario.infoMenu));
    //             localStorage.setItem("data", JSON.stringify(response.data.entity.datosUsuario));
    //             localStorage.setItem("accessToken", response.data.entity.datosUsuario.tknA);
    //             //console.log(response.data.entity.datosUsuario)
    //             localStorage.setItem("menu",
    //                 JSON.stringify(response.data.entity.datosUsuario.infoMenu.listMenu)
    //             );
    //             // window.location.href = rutaDeApp; //Se redirecciona al home

    //         } else {
    //             alert(response.data.entity.message)
    //         }
    //     }
    // }).catch(err => {
    //     console.log("Erorr !", err)
    //     alert("ocurrio un error ! ")
    // })
  };

  return (
    // <div className="polaroid" onClick={Login}>
    //   <div className="card">
    //     <img className="card-img-top" src={urlImg} alt="aplicaciones" />
    //     <div className="card-body">
    //       {/* <p className="card-text">TEsting cards text to build on the card title and make up the bulk of the card's content.</p> */}
    //     </div>
    //   </div>
    // </div>
    <Card hoverable onClick={Login}>
      <img src={urlImg} alt="aplicaciones" />
    </Card>
  );
}
//export default CardItem|
