import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import img1 from "../../../assets/img/Carrusel/img_carrousel01.svg";
//import img2 from "../../../assets/img/Carrusel/img_carrousel02.svg";
//import img3 from "../../../assets/img/Carrusel/img_carrousel03.svg";
//import img4 from "../../../assets/img/Carrusel/img_carrousel04.svg";
import img1 from "../../../assets/img/Carrusel/car_01.svg";
import img2 from "../../../assets/img/Carrusel/car_02.svg";
import img3 from "../../../assets/img/Carrusel/car_03.svg";

export default () => (
    <Carousel style={{ maxWidth: '100%', maxHeight: '100%' }} infiniteLoop={true} transitionTime={12} width={'100%'} autoPlay showArrows={false} showThumbs={false} showStatus={false}>
        <div>
            <img alt="" src={img1} />
        </div>
        <div>
            <img alt="" src={img2} />
        </div>
        <div>
            <img alt="" src={img3} />
        </div>       
    </Carousel>
);

