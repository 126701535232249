import React from "react";
import { Layout } from "antd";
import "./Footer.scss";
import logoINE from "../../../assets/img/Footer/logoINE_bco.svg";

export default function Footer(props) {
  const { Footer } = Layout;

  return (
    <Footer className="footer">
      <div className="footer__instituto">
        <div className="d-md-none">
          <img src={logoINE} alt="Logo" className="center" />
        </div>
        <div className=" d-none d-md-block">
          <img src={logoINE} alt="Logo" />
          <h45>
	          <a className="CAU_INETEL" href="https://www.ine.mx/" target="blank">
	          	<span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
	            	INE México {props.anio}
	          </a>                
            <h45-version>
            	1.0.0 Rev.22 22/10/2020 23:21 
            </h45-version>
            <a className="CAU_INETEL" href="https://cau.ine.mx/" target="blank">
              CAU
            </a>
          </h45>
        </div>
      </div>
      <div className=" d-none d-md-block footer__area">
        <h45>Unidad Técnica de Servicios de Infomática</h45>
      </div>
      <div className="d-none d-md-block footer__version">        
        <h45>
        	Versión del sistema 10.0/2020
        </h45>
      </div>
    </Footer>
  );
}
