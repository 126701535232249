import React from "react";

export default function Page404() {
    return (
        <div>
            <h1>Pagina no encontrada</h1>
        </div>


    );
}