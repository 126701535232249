import moment from 'moment';
import jwtDecode from "jwt-decode";
import CryptoJS from 'crypto-js';
import * as claves from '../Config/claves';
import Cookies from "js-cookie";
 
export const validateToken = (token) => {
    console.log("token" + token);
    if(token === "null" || token === "undefined") {
        let aplicaciones = localStorage.getItem('aplicaciones');
        if(localStorage.getItem("cookieDomain") !== undefined && localStorage.getItem("cookieDomain") !== null) {
            Cookies.remove("us-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
            Cookies.remove("cn-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
            Cookies.remove("vr-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
            Cookies.remove("sy-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
            Cookies.remove("mg-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
        }
        alert("La sesión ha expirado. Favor de ingresar nuevamente.");
        localStorage.clear();
        window.location.href = aplicaciones + "/sesiones/login";
    }

    let jwtToken = jwtDecode(descifraToken(token));

    let expDate = new Date(moment.unix(jwtToken.exp));
    let date = new Date();
    console.log(date+" < "+expDate)
    let tokenValidado = date.getTime() < expDate.getTime() ? true : false;

    if(tokenValidado === false) {
        let aplicaciones = localStorage.getItem('aplicaciones');
        if(localStorage.getItem("cookieDomain") !== undefined && localStorage.getItem("cookieDomain") !== null) {
            Cookies.remove("us-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
            Cookies.remove("cn-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
            Cookies.remove("vr-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
            Cookies.remove("sy-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
            Cookies.remove("mg-ses", { path: "/", domain: localStorage.getItem("cookieDomain") });
        }
        alert("La sesión actual caducó, favor de iniciar sesión de nuevo.");
        localStorage.clear();
        window.location.href = aplicaciones + "/sesiones/login";
    }

    return tokenValidado;
}

export function descifraToken(token) {

    const salt = "dc0da04af8fee58593442bf834b30739";
    const iv = "dc0da04af8fee58593442bf834b30739";

      
    var key = CryptoJS.PBKDF2( 
                claves.CLAVE_TOKEN, 
                CryptoJS.enc.Hex.parse(salt),
                { keySize: (128/32), iterations: 1000 });

    var cipherParams = CryptoJS.lib.CipherParams.create({
                        ciphertext: CryptoJS.enc.Base64.parse(token)
                     });

    var decrypted = CryptoJS.AES.decrypt(
                    cipherParams,
                    key,
                    { iv: CryptoJS.enc.Hex.parse(iv) });

    console.log(decrypted.toString(CryptoJS.enc.Utf8));

    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function validaTimeToken(token) {
    let jwtToken = jwtDecode(descifraToken(token));

    let expDate = new Date(moment.unix(jwtToken.exp));
    let date = new Date();
    console.log(date+" < "+expDate)
    let tokenValidado = date.getTime() < expDate.getTime() ? true : false;

    return tokenValidado;
}