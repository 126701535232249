import React from "react";

//Componentes
import Header from "../Componets/Header/Header";
import Footer from "../Componets/Footer/Footer";
import Carrusel from "../Componets/Carrusel/Carrusel_nuevo";
import Formulario from "../Componets/Formulario/Formulario";
import { isValidateToken } from "../../Router/Auth";
import { Redirect } from 'react-router-dom';
import Cookies from "js-cookie";

import { ReactComponent as Logo_Login } from "../../assets/img/Header/ID_sesiones_negro.svg";
//import "rc-footer/assets/index.css";
import { Layout } from "antd";

const { Content } = Layout; //Se obtiene el componente hijo de Layout

export default function Home() {
  return (
    (isValidateToken() && Cookies.get("sy-ses") !== undefined && localStorage.getItem("username") === null &&  localStorage.getItem("username") === undefined) ?
      <Redirect to = "/sesiones/aplicaciones"/>
    :  <Layout style={{ minHeight: "100vh" }}>
        <Header inicio="" />
        <Content>
          <section className="login-block">
            <div className="container">
              <div className="row">
                <div
                  className="banner-sec d-none d-md-block align-self-center col-md-8 "
                  style={{
                    maxWidth: "100%",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <Carrusel />
                </div>
                <div className="col-md-4 ">
                  <Formulario Logo={Logo_Login} />
                </div>
              </div>
            </div>
          </section>
        </Content>
        <Footer className="footer_login" />
      </Layout>
    
  );
}
